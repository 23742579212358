/** @jsx jsx */

import React from 'react'
import { jsx, css } from '@emotion/core'
import Radio from './svg/radio.svg'
import Card from './svg/card.svg'
import HeartHalf from './svg/heart-half.svg'
import Skull from './svg/skull.svg'
import Shirt from './svg/shirt.svg'
import ColorPalette from './svg/color-palette.svg'
import Power from './svg/power.svg'
import ChatboxEllipses from './svg/chatbox-ellipses.svg'
import CloudUpload from './svg/cloud-upload.svg'
import Flame from './svg/flame.svg'
import Chevron from './svg/chevron-forward.svg'
import Gift from './svg/gift.svg'

export enum IconNames {
  Radio,
  Card,
  HeartHalf,
  Skull,
  Shirt,
  ColorPalette,
  Power,
  ChatboxEllipses,
  CloudUpload,
  Flame,
  Chevron,
  Gift,
}

const IconSrc = {
  [IconNames.Radio]: props => <Radio {...props} />,
  [IconNames.Card]: props => <Card {...props} />,
  [IconNames.HeartHalf]: props => <HeartHalf {...props} />,
  [IconNames.Skull]: props => <Skull {...props} />,
  [IconNames.Shirt]: props => <Shirt {...props} />,
  [IconNames.ColorPalette]: props => <ColorPalette {...props} />,
  [IconNames.Power]: props => <Power {...props} />,
  [IconNames.ChatboxEllipses]: props => <ChatboxEllipses {...props} />,
  [IconNames.CloudUpload]: props => <CloudUpload {...props} />,
  [IconNames.Flame]: props => <Flame {...props} />,
  [IconNames.Chevron]: props => <Chevron {...props} />,
  [IconNames.Gift]: props => <Gift {...props} />,
}

interface Props {
  iconName: IconNames
  className?: string
  color?: string
}

const Icon = ({ iconName, className, color }: Props) => {
  const IconWrapper = IconSrc[iconName]
  return (
    <IconWrapper
      css={css`
        width: 1.5rem;
        height: 1.5rem;
        ${color &&
        css`
          fill: ${color};
        `}
      `}
      className={className}
    />
  )
}

export default Icon
