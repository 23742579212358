/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import { Fonts, Colors } from './css/constants'
import Icon, { IconNames } from './icons/Icons'
import Link from 'next/link'
import { Flex, Heading } from '@chakra-ui/core'

const Stereo = ({}: {}) => (
  <Link href="/">
    <Flex cursor="pointer" alignItems="center" marginTop="1">
      <Heading>
        <Icon
          css={css`
            width: 1em;
            height: 1em;
            fill: ${Colors.YELLOW};
            margin-right: 0.75rem;
          `}
          iconName={IconNames.Radio}
        />
      </Heading>
      <Heading
        css={css`
          ${Fonts.AvantGardeBold}
          white-space: nowrap;
        `}
        fontSize="lg"
        color="white"
      >
        STEREO
      </Heading>
    </Flex>
  </Link>
)

export default Stereo
