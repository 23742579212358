import { HeaderBase } from './Header'
import { Stack, Text, Button } from '@chakra-ui/core'
import { EmailIcon } from '@chakra-ui/icons'

const Footer = () => (
  <HeaderBase
    as="footer"
    right={
      <Stack isInline spacing={6} alignSelf="stretch" alignItems="center">
        <Button
          variant="link"
          size="sm"
          color="white"
          leftIcon={<EmailIcon />}
          onClick={() => window.open('mailto:scott@stereo.live', '_mail')}
          data-analytics="email-button"
        >
          scott@stereo.live
        </Button>
        <Text color="white" fontSize="sm">
          © Stereo, 2020
        </Text>
      </Stack>
    }
  />
)

export default Footer
