/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import Stereo from 'ava/Stereo'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, ElementType } from 'react'
import {
  Stack,
  Button,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Flex,
  Icon,
  DarkMode,
} from '@chakra-ui/core'
import { useAuthUser } from 'firebase/hooks'
import { IoIosMenu } from 'react-icons/io'

const HeaderLink = ({ href, children }) => {
  const router = useRouter()
  return (
    <Link href={href}>
      <Button
        size="lg"
        color="whit"
        textDecor={router.pathname === href ? 'underline' : 'none'}
        variant="link"
        justifyContent="start"
      >
        {children}
      </Button>
    </Link>
  )
}

type HeaderBaseProps = {
  right?: ReactNode
  as: ElementType<any>
}
export const HeaderBase = ({ right, as }: HeaderBaseProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <DarkMode>
      <Flex
        as={as}
        alignItems="center"
        padding={3}
        justifyContent="space-between"
      >
        <IconButton
          icon={<Icon as={IoIosMenu} />}
          isRound
          aria-label="open menu"
          onClick={onOpen}
          display={{ base: 'flex', md: 'none' }}
          data-analytics="menu-button"
          color="white"
          size="sm"
        />
        <Stack
          isInline
          alignItems="center"
          css={css`
            > * {
              margin-right: 0.75em;
            }
          `}
          d={{ xs: 'none', md: 'flex' }}
        >
          <Stereo />
          <HeaderLink href="/how-it-works">How it works</HeaderLink>
        </Stack>

        {right}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerHeader color="white">
              <Stereo />
            </DrawerHeader>
            <DrawerBody>
              <Stack spacing={4}>
                <HeaderLink href="/how-it-works">How it works</HeaderLink>
                <HeaderLink href="/pricing">Pricing</HeaderLink>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </DarkMode>
  )
}

const LoggedIn = () => {
  const { authUser, logout } = useAuthUser()
  const router = useRouter()
  return (
    <Stack isInline spacing={2}>
      <Button
        colorScheme="yellow"
        data-analytics="logout-button"
        variant="outline"
        onClick={logout}
        size="sm"
      >
        Log out
      </Button>
      <Link href="/dashboard">
        <Button
          colorScheme="yellow"
          size="sm"
          data-analytics="dashboard-button"
        >
          {!!authUser && router.pathname === '/dashboard'
            ? authUser.email
            : 'Dashboard'}
        </Button>
      </Link>
    </Stack>
  )
}

const LoggedOut = () => {
  return (
    <Stack isInline spacing={2}>
      <Link href={`/login`}>
        <Button
          colorScheme="yellow"
          variant="outline"
          data-analytics="login-button"
          mr={2}
        >
          Login
        </Button>
      </Link>
      <Link href="/signup">
        <Button colorScheme="yellow" data-analytics="signup-button">
          Sign up
        </Button>
      </Link>
    </Stack>
  )
}
const Header = () => {
  const { authUser } = useAuthUser()
  return <HeaderBase as="header" right={!!authUser ? <LoggedIn /> : null} />
}

export default Header
