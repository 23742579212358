import { css } from '@emotion/core'

export enum Colors {
  PRIMARY_100 = '#021C31',
  PRIMARY_90 = '#031F4F',
  PRIMARY_80 = '#04166D',
  PRIMARY_70 = '#060488',
  PRIMARY_60 = '#2304A0',
  PRIMARY_50 = '#5013B5',
  PRIMARY_40 = '#7F2CC6',
  PRIMARY_30 = '#AC4FD5',
  PRIMARY_20 = '#D27CE2',
  PRIMARY_10 = '#ECB1ED',
  PRIMARY_05 = '#F6D1F2',
  PRIMARY_00 = '#FFF2FC',

  WHITE = '#FFFFFF',
  BLACK = '#000000',
  PINK = '#FF4D5A',
  YELLOW = '#FFCB01',
  BLUE = '#0099FF',
  GREEN = '#03C280',
  RED = '#FF4D5A',
  PURPLE = '#9360FF',
}

export const Fonts = {
  AvantGardeBold: css`
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
  `,
  AvantGardeBook: css`
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
  `,

  RobotoNormal: css`
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-style: normal;
  `,

  RobotoBold: css`
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-style: normal;
  `,

  AcuminProBold: css`
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
  `,

  AcuminProLight: css`
    font-family: acumin-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
  `,

  AcuminProRegular: css`
    font-family: acumin-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
  `,

  AcuminProBlack: css`
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 900;
  `,

  AcuminProMedium: css`
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
  `,

  AcuminProCondensedBold: css`
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 700;
    font-style: normal;
  `,
}
